<template>
  <v-container>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Firma Electrónica</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row class="mt-4">
          <v-col cols="12" md="12">
            <v-card class="mx-auto">
              <v-card-subtitle>
                <v-row>
                  <v-col cols="12" md="1">
                    <v-btn class="ma-2" outlined x-large fab color="primary">
                      <v-icon color="primary">{{ "mdi-draw-pen" }}</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="11">
                    <p class="mt-8">
                      Adquiere aquí tu firma electrónica, la cual es
                      indispensable para la emisión de documentos tributarios
                      electrónicos.
                    </p>
                  </v-col>
                </v-row>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <div class="mt-15 mb-15">
          <p class="text-h2 font-weight-bold text-center">Precios</p>
        </div>
        <v-row>
          <v-col
            cols="12"
            v-for="(plan, i) in plans"
            :key="i"
            md="4"
            class="pt-10"
          >
       
            <v-card
              v-if="plan.price > 0"
              class="mx-auto"
              elevation="10"
              max-width="95%"
            >
              <v-card-title
                v-if="i == 0 && plan.price > 0"
                class="justify-center"
                style="background-color: #0e73b1"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>
              <v-card-title
                v-if="i == 1 && plan.price > 0"
                class="justify-center"
                style="background-color: #e9501c"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>
              <v-card-title
                v-if="i == 2 && plan.price > 0"
                class="justify-center"
                style="background-color: #179db0"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>

              <v-card-text>
                <br />
                <p
                  v-if="i == 0 && plan.price > 0"
                  class="text-center text-h3 font-weight-black"
                  style="color: #0e73b1"
                >
                  {{ plan.price }} CLP
                </p>
                <p
                  v-if="i == 1 && plan.price > 0"
                  class="text-center text-h3 font-weight-black"
                  style="color: #e9501c"
                >
                  {{ plan.price }} CLP
                </p>
                <p
                  v-if="i == 2 && plan.price > 0"
                  class="text-center text-h3 font-weight-black"
                  style="color: #179db0"
                >
                  {{ plan.price }} CLP
                </p>
                <p class="text-center" v-if="plan.price > 0">
                  <strong> IVA incluido</strong>
                </p>
              </v-card-text>

              <v-card-subtitle v-if="plan.price > 0">{{
                plan.description
              }}</v-card-subtitle>
              <v-divider v-if="plan.price > 0"></v-divider>
              <v-card-actions class="justify-center pt-3 pb-3">
                <v-btn
                  @click="contratar(plan.id)"
                  v-if="i == 0 && plan.price > 0"
                  class="white--text"
                  color="#0e73b1"
                >
                  <v-icon right dark> mdi-cart </v-icon> Adquirir
                </v-btn>
                <v-btn
                  @click="contratar(plan.id)"
                  v-if="i == 1 && plan.price > 0"
                  class="white--text"
                  color="#e9501c"
                >
                  <v-icon right dark> mdi-cart </v-icon> Adquirir
                </v-btn>
                <v-btn
                  @click="contratar(plan.id)"
                  v-if="i == 2 && plan.price > 0"
                  class="white--text"
                  color="#179db0"
                >
                  <v-icon right dark> mdi-cart </v-icon> Adquirir
                </v-btn>
              </v-card-actions>
            </v-card></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import Config from "../../config";
export default {
  data: () => ({
    plans: [],
  }),
  computed: {},
  mounted() {
    this.getPlans();
  },
  watch: {},
  methods: {
    async getPlans() {
      let request = {
        id_service: Config.idFirmaElectronica,
      };
      axios
        .post("/plan-by-service", request)
        .then((response) => {
          console.log(response.data);
          this.plans = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener planes");
        });
    },
    contratar(id_plan) {
      this.$router.push({
        name: "create-contract-by-customer",
        params: {
          id_plan: id_plan,
          id_servicio: Config.idBoletaElectronica,
        },
      });
    },
  },
};
</script>